export const Mail_Vars = {
    fb_link: "https://www.facebook.com/",
    tw_link: "https://twitter.com/",
    sitename: "LLJ Group",
    contact_email: "hello@lljgroup.co.uk",
    company_phone: "+9712 495 0500",
    available_time: "9am - 5pm ,",
    available_days: " Monday - Friday",
    address: "PO Box: 107729, Abu Dhabi, UAE",
    primary_color: "#0DB2B2"
}


export const Site_Vars = {
    default_currency: "AED "
}
export const OFF_PLAN = "new_development"